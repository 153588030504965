import logo from './logo.svg';
import './App.css';
import { OrdersAPI } from './API/ordersAPI';
import OrdersTable from './Components/Orders/ordersTable';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {


  return (

        <OrdersTable></OrdersTable>
  );
}

export default App;
