import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table, Spinner, Button, ButtonGroup, Form } from 'react-bootstrap';
import { OrdersAPI } from '../../API/ordersAPI';
import { DateRangePicker } from 'rsuite';

export default function OrdersTable() {
  const [orders, setOrders] = useState();
  const [filteredOrders, setFilteredOrders] = useState();
  useEffect(() => {
    (async () => {
      const orderData = await OrdersAPI.getAllOrders();
      setOrders(orderData);
      orderData.sort((o1, o2) => o2.ID - o1.ID);
      setFilteredOrders(orderData);
    })()
  }, []); 

  const onOrderClose = async (order) => {
    order.Status = 0;
    await OrdersAPI.updateOrder(order);
    const newOrders = [...orders.filter(o => o.ID != order.ID), order];
    setOrders(newOrders);
    newOrders.sort((o1, o2) => o2.ID - o1.ID);
    setFilteredOrders(newOrders);
  }

  const onOrderProlongation = async (order) => {
    const orderDate = new Date(order.ExpirationDate);
    const prolongationFieldValue = document.getElementById(`prolongation-${order.ID}`).value;
    orderDate.setDate(orderDate.getDate() + new Number(prolongationFieldValue));
    const newOrder = {...order, ExpirationDate: orderDate.toISOString().split('T')[0], ID: order.ID + 1};
    OrdersAPI.insertOrder(newOrder);
    order.Status = 0;
    OrdersAPI.updateOrder(order);
    const newOrders = [...orders.filter(o => o.ID != order.ID), order, newOrder];
    setOrders(newOrders); 
    newOrders.sort((o1, o2) => o2.ID - o1.ID);
    setFilteredOrders(newOrders);
  }


  return (
    <>
      <DateRangePicker onOk={(value) => {
        setFilteredOrders(orders.filter(o => {
          const orderDate = new Date(o.ExpirationDate);
          return orderDate >= value[0] && orderDate <= value[1];
        }))
      }}
      onClean={() => setFilteredOrders(orders)}/>
      {
        filteredOrders ?
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Статус</th>
                <th>Номер заказа</th>
                <th>Номер договора</th>
                <th>Модель тренажера</th>
                <th>Дата окончания</th>
                <th>Телефон</th>
                <th>Адрес</th>
                <th>Сумма</th>
                <th>Метод оплаты</th>
                <th>Комментарий</th>
                <th>Действие</th>
              </tr>
            </thead>
            <tbody>
              {
                filteredOrders?.map((o) =>
                  <tr key = {o.ID}>
                    <td>{o.Status}</td>
                    <td>{o.OrderNumber}</td>
                    <td>{o.ContractNumber}</td>
                    <td>{o.Name}</td>
                    <td>{o.ExpirationDate}</td>
                    <td>{o.Phone}</td>
                    <td>{o.Address}</td>
                    <td>{o.Total}</td>
                    <td>{o.PaymantType}</td>
                    <td>{o.Comment}</td>

                    <td><ButtonGroup aria-label="First group">
                      <Button variant="outline-info">Изменить</Button>
                      <Button variant="outline-danger" onClick={() => onOrderClose(o)}>Завершить</Button>
                      <div> 
                        <Button variant="outline-success" onClick={() => onOrderProlongation(o)}>Продлить</Button>
                        <Form.Control
                          id={`prolongation-${o.ID}`}
                        />
                      </div>
                    </ButtonGroup></td>

                  </tr>
                )


              }
            </tbody>
          </Table> : <Spinner animation="border" />}
    </>
  );
}
