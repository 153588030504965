const apiUrl = "https://management.sportzaldoma.by/";

const request = async (url, method, body) => {
  const response = await sendRequest(url, method, body);
  return await handleResponse(response);
};

const sendRequest = async (url, method, body) => {
  try {
    const response = await fetch(apiUrl + url, {
      method: method,
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(body),
    });
    return response;
  } catch (e) {
    console.log(e);
  }
};

const handleResponse = async (response) => {
  if (response.ok) {
    const text = await response.text();
    return text ? JSON.parse(text) : {};
  }
};

const getRequest = async (url) => {
  return await request(url, "GET");
};

const postRequest = async (url, body) => {
  return await request(url, "POST", body);
};

export const FetchAPI = {
  getRequest,
  postRequest,
};
