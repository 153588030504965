import { FetchAPI } from "./fetchApi"

const getAllOrders = async () => {
    const orders = await FetchAPI.getRequest("get-orders");
    return orders;
}

const updateOrder = async (order) => {

    await FetchAPI.postRequest("update-order",order);

}

const insertOrder = async (order) => {
    await FetchAPI.postRequest("insert-order", order);
}
export const OrdersAPI = {
    getAllOrders, 
    updateOrder,
    insertOrder
}

